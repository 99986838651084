import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import { Container, Wrapper } from "../transaction/general.styles";
import { EventAvailable, Search } from "@mui/icons-material";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Btn,
  Input,
  InputWrapper,
  Left,
  ReportWrapper,
  Right,
  Title,
} from "./daily.styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { monthlyReport } from "../../Redux/apiCalls";
import { Loading } from "../transaction/pending.styles";
import { formatAmount } from "../../utills/formatNumber";

const Monthly = () => {
  const dispatch = useDispatch();
  const { monthly, isFetching } = useSelector((state) => state.report);
  const [selectedDate, setSelectedDate] = useState("");

  // Set initial selected month to current month
  useEffect(() => {
    const today = new Date();
    const currentMonth = today.toISOString().slice(0, 7); // YYYY-MM format
    setSelectedDate(currentMonth);
  }, []);

  const formatMonthToDisplay = (date) => {
    const options = { year: "numeric", month: "long" };
    return new Date(`${date}-01`).toLocaleDateString("en-US", options); // Format to "April, 2023"
  };

  // Fetch the monthly report whenever selectedDate changes
  useEffect(() => {
    if (selectedDate) {
      monthlyReport(dispatch, selectedDate);
    }
  }, [dispatch, selectedDate]);

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Monthly Report</H3>
            <DescP>
              Reports / <DescSpan>Monthly Report</DescSpan>
            </DescP>
          </Desc>
          <ReportWrapper>
            <Left>
              <Title>Search</Title>
              <InputWrapper>
                <EventAvailable
                  style={{ padding: "5px", fontSize: "30px", color: "#495057" }}
                />
                <Input
                  type="month"
                  value={selectedDate} 
                  onChange={(e) => setSelectedDate(e.target.value)} 
                />
              </InputWrapper>
              <Btn>
                <Search />
                Search
              </Btn>
            </Left>
            <Right>
              <Title>
                Monthly Report for {formatMonthToDisplay(selectedDate)}
              </Title>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#f3f2f7" }}>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isFetching ? (
                      <Loading>
                        <CircularProgress style={{ color: "blue" }} />
                      </Loading>
                    ) : (
                      monthly &&
                      Object.entries(monthly).map(([key, value]) => (
                        <TableRow
                          key={key}
                          style={{ backgroundColor: "#f3f2f7" }}
                        >
                          <TableCell
                            style={{
                              color: "#8887a9",
                              textTransform: "capitalize",
                            }}
                          >
                            {key.replace(/_/g, " ")}{" "}
                            {/* Replace underscores with spaces for better readability */}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {key.includes("amount")
                              ? `₦${formatAmount(value)}`
                              : value}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Right>
          </ReportWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default Monthly;
