import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import { Container, Wrapper } from "../transaction/general.styles";
import { EventAvailable, Search } from "@mui/icons-material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Btn,
  InputWrapper,
  Left,
  ReportWrapper,
  Right,
  Title,
} from "./daily.styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { yearlyReport } from "../../Redux/apiCalls";
import { CircularProgress } from "@mui/material";
import { Loading } from "../transaction/pending.styles";
import { formatAmount } from "../../utills/formatNumber";

const Yearly = () => {
  const dispatch = useDispatch();
  const { yearly, isFetching } = useSelector((state) => state.report);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());


  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 2000; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  // Fetch yearly report when the year changes
  useEffect(() => {
    if (selectedYear) {
      yearlyReport(dispatch, selectedYear); // Call the action to fetch yearly report data
    }
  }, [dispatch, selectedYear]);

  console.log(yearly);

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Yearly Report</H3>
            <DescP>
              Reports / <DescSpan>Yearly Report</DescSpan>
            </DescP>
          </Desc>
          <ReportWrapper>
            <Left>
              <Title>Search</Title>
              <InputWrapper>
                <EventAvailable
                  style={{ padding: "5px", fontSize: "30px", color: "#495057" }}
                />
                {/* Dropdown for selecting year */}
                <select
                  value={selectedYear} // Display selected year
                  onChange={(e) => setSelectedYear(parseInt(e.target.value))} // Update year on change
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    width: '100%'
                  }}
                >
                  {getYearOptions().map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </InputWrapper>
              <Btn>
                <Search />
                Search
              </Btn>
            </Left>
            <Right>
              <Title>Yearly Report for {selectedYear}</Title>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#f3f2f7" }}>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isFetching ? (
                      <Loading>
                        <CircularProgress style={{ color: "blue" }} />
                      </Loading>
                    ) : (
                      yearly &&
                      Object.entries(yearly).map(([key, value]) => (
                        <TableRow
                          key={key}
                          style={{ backgroundColor: "#f3f2f7" }}
                        >
                          <TableCell
                            style={{
                              color: "#8887a9",
                              textTransform: "capitalize",
                            }}
                          >
                            {key.replace(/_/g, " ")}{" "}
                            {/* Replace underscores with spaces for better readability */}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {key.includes("amount")
                              ? `₦${formatAmount(value)}`
                              : value}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Right>
          </ReportWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default Yearly;
