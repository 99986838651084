import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import { Container, Wrapper } from "../transaction/general.styles";
import { EventAvailable, Search } from "@mui/icons-material";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Btn,
  Input,
  InputWrapper,
  Left,
  ReportWrapper,
  Right,
  Title,
} from "./daily.styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dailyReport } from "../../Redux/apiCalls";
import { Loading } from "../transaction/pending.styles";
import { formatAmount } from "../../utills/formatNumber";

const Daily = () => {
  const dispatch = useDispatch();
  const { daily, isFetching } = useSelector((state) => state.report);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0]; // YYYY-MM-DD format
    setSelectedDate(formattedDate);
  }, []);

  const formatDateToDisplay = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options); // Format to "10 April, 2023"
  };

  useEffect(() => {
    dailyReport(dispatch, selectedDate);
  }, [dispatch, selectedDate]);

  console.log(daily, "monthh");

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Daily Report</H3>
            <DescP>
              Reports / <DescSpan>Daily Report</DescSpan>
            </DescP>
          </Desc>
          <ReportWrapper>
            <Left>
              <Title>Search</Title>
              <InputWrapper>
                <EventAvailable
                  style={{ padding: "5px", fontSize: "30px", color: "#495057" }}
                />
                <Input
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </InputWrapper>
              <Btn>
                <Search />
                Search
              </Btn>
            </Left>
            <Right>
              <Title>
                Daily Report for {formatDateToDisplay(selectedDate)}
              </Title>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#f3f2f7" }}>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isFetching ? (
                      <Loading>
                        <CircularProgress style={{ color: "blue" }} />
                      </Loading>
                    ) : (
                      daily &&
                      Object.entries(daily).map(([key, value]) => (
                        <TableRow
                          key={key}
                          style={{ backgroundColor: "#f3f2f7" }}
                        >
                          <TableCell
                            style={{
                              color: "#8887a9",
                              textTransform: "capitalize",
                            }}
                          >
                            {key.replace(/_/g, " ")}{" "}
                            {/* Replace underscores with spaces for better readability */}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {key.includes("amount")
                              ? `₦${formatAmount(value)}`
                              : value}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Right>
          </ReportWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default Daily;
